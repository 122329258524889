import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a53d13b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner" }
const _hoisted_2 = { class: "cont-wrap" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLink = _resolveComponent("AppLink")!
  const _component_GuideSdkIcon = _resolveComponent("GuideSdkIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.data.title), 1),
      _createElementVNode("p", {
        class: "description",
        innerHTML: _ctx.data.description
      }, null, 8, _hoisted_4),
      _createVNode(_component_AppLink, {
        href: _ctx.data.url,
        text: '바로가기',
        target: "_blank",
        class: "link btn-primary",
        onClick: _ctx.trackEventGuideSdk
      }, null, 8, ["href", "onClick"]),
      _createVNode(_component_GuideSdkIcon, { class: "icon" })
    ])
  ]))
}