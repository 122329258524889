
import { defineComponent } from "vue";
import { trackerSetClick } from "@/utils/vertebraSdk";
import { gtagEvents } from "@/shared/gtagEvents";
import GuideBoxIcon from "@/components/atoms/icon/GuideBoxIcon/GuideBoxIcon.vue";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
export default defineComponent({
  name: "GuideBoxContainer",
  components: {
    AppLink,
    GuideBoxIcon
  },
  props: {
    data: {
      type: Object
    }
  },
  setup() {
    const trackEventGuideDocuments = () => {
      gtagEvents("documents 바로가기");
      trackerSetClick("go-documents");
    };
    const trackEventGuideCommunity = () => {
      gtagEvents("community 바로가기");
      trackerSetClick("go-community");
    };
    const trackEventGuideSampleApp = () => {
      gtagEvents("sampleapp 바로가기");
      trackerSetClick("go-sampleapp");
    };
    const trackEventsList = [
      trackEventGuideDocuments,
      trackEventGuideCommunity,
      trackEventGuideSampleApp
    ];
    return {
      trackEventsList
    };
  }
});
