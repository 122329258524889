import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2f12e34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cont-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubIntroContentMobile = _resolveComponent("SubIntroContentMobile")!
  const _component_AppLink = _resolveComponent("AppLink")!
  const _component_GuideBoxContainerMobile = _resolveComponent("GuideBoxContainerMobile")!
  const _component_GuideFaqMobile = _resolveComponent("GuideFaqMobile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SubIntroContentMobile, {
        title: _ctx.title,
        description: _ctx.description
      }, null, 8, ["title", "description"]),
      _createVNode(_component_AppLink, {
        class: "btn btn-primary guide",
        text: "SDK 다운로드(Github)",
        href: _ctx.sdk.url,
        target: "_blank"
      }, null, 8, ["href"])
    ]),
    _createVNode(_component_GuideBoxContainerMobile, { data: _ctx.support }, null, 8, ["data"]),
    _createVNode(_component_GuideFaqMobile, { data: _ctx.faq }, null, 8, ["data"])
  ], 64))
}