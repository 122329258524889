
import { defineComponent } from "vue";
import { trackerSetClick } from "@/utils/vertebraSdk";
import { gtagEvents } from "@/shared/gtagEvents";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import GuideSdkIcon from "@/components/atoms/icon/GuideSdkIcon/GuideSdkIcon.vue";
export default defineComponent({
  components: {
    AppLink,
    GuideSdkIcon
  },
  props: {
    data: {
      type: Object,
      require: true
    }
  },
  setup() {
    const trackEventGuideSdk = () => {
      gtagEvents("github 바로가기");
      trackerSetClick("go-github");
    };
    return {
      trackEventGuideSdk
    };
  }
});
