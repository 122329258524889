
import { defineComponent, onMounted } from "vue";
import { trackerSetAction } from "@/utils/vertebraSdk";
import { useHead } from "@vueuse/head";
import { data } from "@/shared/guide";
import SubIntroContentMobile from "@/components/templates/SubIntroContent/SubIntroContentMobile.vue";
import GuideBoxContainerMobile from "@/components/templates/GuideBoxContainer/GuideBoxContainerMobile.vue";
import GuideSdkBannerMobile from "@/components/templates/GuideSdkBanner/GuideSdkBannerMobile.vue";
import GuideFaqMobile from "@/components/templates/GuideFaq/GuideFaqMobile.vue";
import GuideCardContainerMobile from "@/components/templates/GuideCardContainer/GuideCardContainerMobile.vue";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
export default defineComponent({
  components: {
    SubIntroContentMobile,
    GuideBoxContainerMobile,
    GuideSdkBannerMobile,
    GuideFaqMobile,
    GuideCardContainerMobile,
    AppLink
  },
  setup() {
    useHead({
      title: data.sitemapTitle,
      link: [
        { rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}/guide` }
      ]
    });
    const { title, description, support, sdk, faq, contact } = data.mobile;
    onMounted(() => {
      trackerSetAction("개발가이드 페이지");
    });
    return {
      title,
      description,
      support,
      sdk,
      faq,
      contact
    };
  }
});
