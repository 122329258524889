import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fd48f96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cont-wrap" }
const _hoisted_2 = { class: "card-inner" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuideCardArrowIcon = _resolveComponent("GuideCardArrowIcon")!
  const _component_AppLink = _resolveComponent("AppLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createBlock(_component_AppLink, {
        key: index,
        href: index === 1 ? `mailto:${item.url}` : item.url,
        target: index === 1 ? null : '_blank',
        class: "card",
        onClick: _ctx.trackEventsList[index]
      }, {
        linkSlot: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("strong", {
              class: "title",
              innerHTML: item.description
            }, null, 8, _hoisted_3),
            _createElementVNode("span", {
              class: "description",
              innerHTML: item.title
            }, null, 8, _hoisted_4)
          ]),
          _createVNode(_component_GuideCardArrowIcon, { class: "icon" })
        ]),
        _: 2
      }, 1032, ["href", "target", "onClick"]))
    }), 128))
  ]))
}