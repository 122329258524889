import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35cd89d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fluid-wrap" }
const _hoisted_2 = { class: "contents-wrap" }
const _hoisted_3 = { class: "screen-out" }
const _hoisted_4 = { class: "link-inner" }
const _hoisted_5 = { class: "frame" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "shortcut"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuideBoxIcon = _resolveComponent("GuideBoxIcon")!
  const _component_AppLink = _resolveComponent("AppLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.data.title), 1),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.list, (item, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, [
            _createVNode(_component_AppLink, {
              href: item.url,
              target: "_blank",
              onClick: _ctx.trackEventsList[index]
            }, {
              linkSlot: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, [
                    _createVNode(_component_GuideBoxIcon, {
                      iconType: item.name,
                      class: "guidebox"
                    }, null, 8, ["iconType"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("strong", _hoisted_7, _toDisplayString(item.title), 1),
                    _createElementVNode("p", {
                      class: "description",
                      innerHTML: item.description
                    }, null, 8, _hoisted_8),
                    (item.url)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(item.linktitle), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["href", "onClick"])
          ]))
        }), 128))
      ])
    ])
  ]))
}