
import { defineComponent } from "vue";
import { trackerSetClick } from "@/utils/vertebraSdk";
import { gtagEvents } from "@/shared/gtagEvents";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import GuideCardArrowIcon from "@/components/atoms/icon/GuideCardArrowIcon/GuideCardArrowIcon.vue";
export default defineComponent({
  components: {
    AppLink,
    GuideCardArrowIcon
  },
  props: {
    data: {
      type: Object,
      require: true
    }
  },
  setup() {
    const trackEventGuideCommunityCard = () => {
      gtagEvents("커뮤니티 바로가기");
      trackerSetClick("go-community");
    };
    const trackEventGuideEmailCard = () => {
      gtagEvents("email 문의하기");
      trackerSetClick("ask-email");
    };
    const trackEventGuideBlogCard = () => {
      gtagEvents("blog 바로가기");
      trackerSetClick("go-blog");
    };
    const trackEventsList = [
      trackEventGuideCommunityCard,
      trackEventGuideEmailCard,
      trackEventGuideBlogCard
    ];
    return {
      trackEventsList
    };
  }
});
