<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="24"
    fill="none"
    viewBox="0 0 27 24"
  >
    <path
      fill="#000"
      d="M27 12L15.75 0l-1.96 1.885 8.274 8.701H0v2.828h22.064l-8.274 8.7L15.75 24 27 12z"
      opacity=".8"
    />
  </svg>
</template>
