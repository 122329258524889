<template>
  <svg
    width="402"
    height="284"
    viewBox="0 0 402 284"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <rect y="179" width="49" height="36" rx="10" fill="#AFC3FF" />
      <rect x="240" y="220" width="64" height="64" rx="10" fill="#AFC3FF" />
      <rect x="260" width="142" height="104" rx="10" fill="#789AFF" />
      <rect
        width="45"
        height="3"
        rx="1.5"
        transform="matrix(1 0 0 -1 341 22)"
        fill="white"
        fill-opacity="0.3"
      />
      <rect
        width="45"
        height="3"
        rx="1.5"
        transform="matrix(1 0 0 -1 341 34)"
        fill="white"
        fill-opacity="0.3"
      />
      <rect
        width="45"
        height="3"
        rx="1.5"
        transform="matrix(1 0 0 -1 341 46)"
        fill="white"
        fill-opacity="0.3"
      />
    </g>
    <path
      d="M76 73C76 67.4771 80.4772 63 86 63H326C331.523 63 336 67.4772 336 73V245C336 250.523 331.523 255 326 255H86C80.4772 255 76 250.523 76 245V73Z"
      fill="black"
    />
    <path
      d="M70 67C70 61.4771 74.4772 57 80 57H320C325.523 57 330 61.4772 330 67V239C330 244.523 325.523 249 320 249H80C74.4772 249 70 244.523 70 239V67Z"
      fill="white"
    />
    <path
      d="M64 105V147V233C64 238.523 68.4772 243 74 243H314C319.523 243 324 238.523 324 233V61C324 55.4771 319.523 51 314 51H194H139"
      stroke="#333333"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M124.561 33C127.013 33 129 35.0098 129 37.489V90.511C129 92.9902 127.013 95 124.561 95H110.869V108.23C110.869 109.83 108.957 110.631 107.839 109.5L94.3151 95L31.4386 95C28.9872 95 27 92.9902 27 90.511V37.489C27 35.0098 28.9873 33 31.4386 33H124.561Z"
      fill="#FFE200"
    />
    <path
      d="M275 203C275 195.82 280.82 190 288 190H331V216H288C280.82 216 275 210.18 275 203Z"
      fill="black"
    />
    <rect x="270" y="185" width="104" height="26" rx="13" fill="#3D6DFF" />
    <path
      d="M290 198H362"
      stroke="#333333"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <rect x="282" y="193" width="10" height="10" rx="5" fill="#333333" />
    <path
      d="M56 61.0001C52.6863 61.0001 50 58.3138 50 55.0001C50 51.6864 52.6863 49.0001 56 49.0001C59.3137 49.0001 62 51.6864 62 55.0001C62 58.3138 59.3137 61.0001 56 61.0001Z"
      fill="#BA9C00"
    />
    <path
      d="M56 65C63.9238 65 70.4129 70.7349 70.9623 78C71.004 78.5507 70.5523 79 70 79H42C41.4477 79 40.996 78.5507 41.0377 78C41.5871 70.7349 48.0762 65 56 65Z"
      fill="#BA9C00"
    />
    <path
      d="M83 51.0001H113"
      stroke="#D0AF00"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M83 64.0001H113"
      stroke="#D0AF00"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M83 77.0001H113"
      stroke="#D0AF00"
      stroke-width="3"
      stroke-linecap="round"
    />
    <rect x="141" y="98" width="106" height="106" rx="53" fill="black" />
    <rect x="300" y="68" width="8" height="8" rx="4" fill="#FFE200" />
    <rect x="286" y="68" width="8" height="8" rx="4" fill="#FFE200" />
    <path
      d="M216.466 131.805C217.526 129.292 218.233 125.779 216.324 122.327C216.236 122.177 216.085 122.071 215.909 122.053C211.984 121.646 209.032 123.673 207.14 125.646C205.372 125.115 203.498 124.823 201.554 124.823H187.411C185.67 124.823 183.99 125.053 182.391 125.487C180.49 123.566 177.591 121.655 173.772 122.053C173.596 122.071 173.445 122.177 173.357 122.327C171.58 125.531 172.066 128.779 172.995 131.239C169.874 134.69 167.974 139.274 167.974 144.292C167.974 155.044 176.68 163.761 187.42 163.761H187.553C186.094 165.496 185.21 167.735 185.21 170.177V170.956C185.184 170.965 185.157 170.965 185.14 170.973C182.585 172.018 179.801 172.018 177.29 170.956C175.134 170.044 173.419 168.434 172.703 166.646C170.626 161.442 166.365 160.319 166.188 160.274C165.251 160.044 164.306 160.611 164.058 161.54C163.811 162.478 164.376 163.442 165.305 163.699C165.419 163.726 168.036 164.496 169.415 167.956C170.467 170.593 172.897 172.929 175.903 174.212C177.609 174.938 179.403 175.292 181.215 175.292C182.55 175.292 183.893 175.088 185.201 174.699V183.389C185.201 184.283 185.926 185 186.81 185H203.481C204.374 185 205.09 184.274 205.09 183.389V170.177C205.09 167.717 204.188 165.46 202.712 163.726C212.912 163.124 221 154.655 221 144.292C221 139.54 219.294 135.186 216.466 131.805Z"
      fill="white"
    />
  </svg>
</template>
